import { useState } from 'react';

import axios from 'axios';
import { toast } from 'react-toastify';
import Button from '../Button';
import CustomCheckbox from './CustomCheckbox';
import CustomTextInput from './CustomTextInput';
import CustomTextarea from './CustomTextarea';

function ContactForm() {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [form, setForm] = useState({
    name: '',
    lastname: '',
    email: '',
    phone: '',
    message: '',
    agreements: false,
  });

  const onSubmit = () => {
    if (
      form.name == '' ||
      form.lastname == '' ||
      form.email == '' ||
      form.phone == '' ||
      form.message == ''
    ) {
      setErrorMessage('Uzupełnij wszystkie pola formularza');
      return;
    } else {
      setErrorMessage(null);
    }

    toast.promise(axios.post('https://api.binnar.com.pl/contact/', form), {
      pending: 'Wysyłanie wiadomości...',
      success: 'Wysłano wiadomość',
      error: 'Wystąpił błąd przy wysyłaniu',
    });
  };

  return (
    <div className="w-[90%] lg:w-3/4 flex flex-col gap-4 lg:gap-8" id="contact">
      <div className="gap-1 md:gap-4 lg:gap-8 grid grid-cols-1 md:grid-cols-2">
        <CustomTextInput
          label="Imię"
          placeholder="Wpisz imię"
          value={form.name}
          onChange={(val) => setForm({ ...form, name: val })}
        />
        <CustomTextInput
          label="Nazwisko"
          placeholder="Wpisz nazwisko"
          value={form.lastname}
          onChange={(val) => setForm({ ...form, lastname: val })}
        />
        <CustomTextInput
          label="E-mail"
          placeholder="Wpisz e-mail"
          value={form.email}
          onChange={(val) => setForm({ ...form, email: val })}
        />
        <CustomTextInput
          label="Numer telefonu"
          placeholder="+48000000000"
          value={form.phone}
          onChange={(val) => setForm({ ...form, phone: val })}
        />
      </div>
      <CustomTextarea
        label="Wiadomość"
        placeholder="Napisz wiadomość"
        value={form.message}
        onChange={(val) => setForm({ ...form, message: val })}
      />
      {errorMessage && (
        <div className="!text-red-500 !font-extrabold">
          <>{errorMessage}</>
        </div>
      )}
      <div className="flex flex-col-reverse lg:flex-row gap-4">
        <Button
          label="Wyślij wiadomość"
          onClick={onSubmit}
          class="!min-w-[218px] !min-h-[54px] !max-w-[218px] !max-h-[54px]"
          disabled={!form.agreements}
        />
        <CustomCheckbox
          labelClass="!text-[12px] text-default"
          value={form.agreements}
          onChange={(val) => setForm({ ...form, agreements: val })}
          label="Wyrażam zgodę na przetwarzanie moich danych osobowych w celach marketingowych i promocyjnych przez BINNAR POLSKA SPÓŁKA Z OGRANICZONĄ ODPOWIEDZIALNOŚCIĄ KRS 0000899373 NIP 1990126403 Regon 388888921, Adres ul. LESIANY 27 47-341 STRADUNIA OPOLSKIE"
        />
      </div>
    </div>
  );
}

export default ContactForm;
